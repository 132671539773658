import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout/"

export default ({ data }) => (
  <Layout>
    <div className="container container-main">
      <Img
        alt="Pigeons' Rock of Raouché, Beirut"
        resolutions={data.file.childImageSharp.resolutions}
        style={{ float: "right", marginLeft: "2rem", marginBottom: "2rem" }}
      />

      <h1>Our Story</h1>

      <p className="lead">
        Damoori Kitchen was started with a simple premise about Lebanese food:
        that nothing's better than the food cooked by <i>teta</i> (grandma).
      </p>

      <p>
        Executive Chef and co-owner Andrea Matni-Ryan was raised in North
        Seattle to Lebanese parents and grew up in Seattle's Lebanese community.
        Her summers were spent visiting family in Lebanon or hosting Lebanese
        relatives visiting Seattle. Whether at home in Western Washington or
        back in the "old country", huge communal meals of home-cooked Lebanese
        food were a fixture: dishes like saniyet kibbeh, ruz wi djaj, fattoush,
        freshly-made hummus or baba.
      </p>

      <p>
        Andrea met husband and co-owner Conor Ryan at Western Washington
        University in Bellingham, and Conor was hooked the first time he tried
        saniyet kibbeh smothered in taratour sauce. Together, they kept
        returning to the question &mdash; why wasn't it easier to find food like
        this? It was healthy and delicious, but also simple. Not many
        ingredients, and mostly unprocessed ones. Sure, there was plenty of
        generic Mediterranean or Middle Eastern food available. But what about
        about the food that <i>teta</i> makes?
      </p>

      <p>
        Named after Andrea’s father's hometown of Damour, Lebanon, Andrea and
        Conor opened Damoori Kitchen in 2017 with the sole purpose of sharing
        homestyle Lebanese cuisine. The dishes are created from Andrea's
        family's recipes passed down through generations and crafted with fresh,
        authentic ingredients. They are driven by that authenticity because, at
        its heart, Lebanese food is more than a delicious meal, it’s about
        bringing people together.
      </p>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query GatsbyImageSampleQuery {
    file(relativePath: { eq: "pigeons-rock.jpg" }) {
      childImageSharp {
        resolutions(width: 300) {
          ...GatsbyImageSharpResolutions
        }
      }
    }
  }
`
